// State mapping for full names to abbreviations and vice versa
export const states = [
  {name: "Alabama", abbrev: "AL"},
  {name: "Alaska", abbrev: "AK"},
  {name: "American Samoa", abbrev: "AS"},
  {name: "Arizona", abbrev: "AZ"},
  {name: "Arkansas", abbrev: "AR"},
  {name: "California", abbrev: "CA"},
  {name: "Colorado", abbrev: "CO"},
  {name: "Connecticut", abbrev: "CT"},
  {name: "Delaware", abbrev: "DE"},
  {name: "District Of Columbia", abbrev: "DC"},
  {name: "Federated States Of Micronesia", abbrev: "FM"},
  {name: "Florida", abbrev: "FL"},
  {name: "Georgia", abbrev: "GA"},
  {name: "Guam", abbrev: "GU"},
  {name: "Hawaii", abbrev: "HI"},
  {name: "Idaho", abbrev: "ID"},
  {name: "Illinois", abbrev: "IL"},
  {name: "Indiana", abbrev: "IN"},
  {name: "Iowa", abbrev: "IA"},
  {name: "Kansas", abbrev: "KS"},
  {name: "Kentucky", abbrev: "KY"},
  {name: "Louisiana", abbrev: "LA"},
  {name: "Maine", abbrev: "ME"},
  {name: "Marshall Islands", abbrev: "MH"},
  {name: "Maryland", abbrev: "MD"},
  {name: "Massachusetts", abbrev: "MA"},
  {name: "Michigan", abbrev: "MI"},
  {name: "Minnesota", abbrev: "MN"},
  {name: "Mississippi", abbrev: "MS"},
  {name: "Missouri", abbrev: "MO"},
  {name: "Montana", abbrev: "MT"},
  {name: "Nebraska", abbrev: "NE"},
  {name: "Nevada", abbrev: "NV"},
  {name: "New Hampshire", abbrev: "NH"},
  {name: "New Jersey", abbrev: "NJ"},
  {name: "New Mexico", abbrev: "NM"},
  {name: "New York", abbrev: "NY"},
  {name: "North Carolina", abbrev: "NC"},
  {name: "North Dakota", abbrev: "ND"},
  {name: "Northern Mariana Islands", abbrev: "MP"},
  {name: "Ohio", abbrev: "OH"},
  {name: "Oklahoma", abbrev: "OK"},
  {name: "Oregon", abbrev: "OR"},
  {name: "Palau", abbrev: "PW"},
  {name: "Pennsylvania", abbrev: "PA"},
  {name: "Puerto Rico", abbrev: "PR"},
  {name: "Rhode Island", abbrev: "RI"},
  {name: "South Carolina", abbrev: "SC"},
  {name: "South Dakota", abbrev: "SD"},
  {name: "Tennessee", abbrev: "TN"},
  {name: "Texas", abbrev: "TX"},
  {name: "Utah", abbrev: "UT"},
  {name: "Vermont", abbrev: "VT"},
  {name: "Virgin Islands", abbrev: "VI"},
  {name: "Virginia", abbrev: "VA"},
  {name: "Washington", abbrev: "WA"},
  {name: "West Virginia", abbrev: "WV"},
  {name: "Wisconsin", abbrev: "WI"},
  {name: "Wyoming", abbrev: "WY"},
];

// Create mapping objects for easy lookup
export const STATE_MAPPING = states.reduce((acc, state) => {
  acc[state.abbrev] = state.name;
  return acc;
}, {});

export const STATE_ABBREVIATIONS = states.reduce((acc, state) => {
  acc[state.name] = state.abbrev;
  return acc;
}, {});

// Helper functions
export const getFullStateName = (stateAbbr) => {
  if (!stateAbbr) return null;
  const upperState = stateAbbr.trim().toUpperCase();
  // If it's already a full state name, return it as is
  if (Object.values(STATE_MAPPING).includes(stateAbbr)) {
    return stateAbbr;
  }
  // Return the full state name or the original value if not found
  return STATE_MAPPING[upperState] || stateAbbr;
};

export const getStateAbbreviation = (fullStateName) => {
  if (!fullStateName) return null;
  // If it's already an abbreviation, return it as is
  if (Object.keys(STATE_MAPPING).includes(fullStateName.toUpperCase())) {
    return fullStateName.toUpperCase();
  }
  // Return the abbreviation or the original value if not found
  return STATE_ABBREVIATIONS[fullStateName] || fullStateName;
};
