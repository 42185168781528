import React, { useState } from "react"
import { IdealSplitTabs } from "../../vendor/mui-pro/src/components/CustomTabs/CustomTabs"
import { makeStyles, Typography, FormControl, Select, MenuItem } from "@material-ui/core"
import { CustomButton } from "../CustomButtons/CustomButton"
import StateSelect from "../Selects/StateSelect"
import CommunityResourceTypeSelect from "../Selects/CommunityResourceTypeSelect"
import Colors from "../../constants/Colors"
import { createStyles } from "@material-ui/styles"
import clsx from "clsx"
import Map from "../map"
import { fromJS } from "immutable"
import CurrentLocationFinder from "../CurrentLocationFinder"
import {distance} from '@turf/turf'
import { Link, graphql, useStaticQuery } from "gatsby"
import customSelectStyles from "../../vendor/mui-pro/src/assets/jss/material-kit-pro-react/customSelectStyle"
import List from "@material-ui/icons/List";
import MapIcon from "@material-ui/icons/Map";
import Button from "../../vendor/mui-pro/src/components/CustomButtons/Button"
import { getDirections } from "../../hooks/routing"
import { standardPadding } from "../../constants/Spacing"
import { getStateAbbreviation } from "../../constants/States"

const selectStyles = makeStyles(customSelectStyles);

const useStyles = makeStyles((theme) => createStyles({
  cardOuterContainer: {
    paddingTop: 10,
    backgroundColor: Colors.pickledBluewood,
    ...standardPadding.standardWidgetBottomPadding,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      ...standardPadding.standardWidgetBottomPaddingMobile,
    },
    [theme.breakpoints.up('md')]: {
      "& > div:first-child > div:first-child > div:first-child": {
        width: "fit-content",
      },
    },
  },
  customCardBody:{  //card root style to apply
    height: "fit-content",
    "& > div":{
      height: "100%",
    }
  },
  noPadding: {
    padding: 0,
  },
  secondTab: {
    [theme.breakpoints.down("sm")]:{
      padding: "0px 15pt 12px 15pt"
    },
    display: "flex",
    alignItems: "center",
    borderRadius: 2,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      //marginBottom: "-23px",
      marginBottom: "-4px",
    },
    "& > *:not(:first-child)" : {
      marginLeft: 10
    },
  },
  visibleMedium: {
    [theme.breakpoints.down("sm")]:{
      display: "none"
    },
  },
  visibleSmall: {
    [theme.breakpoints.up("md")]:{
      width: "calc(65% - 14px)",
      height: 79,
      transform: `translate(58%, -79px)`,
      backgroundColor: Colors.kashmirBlue,
      borderRadius: "6px",
      //marginBottom: "-95px"
      marginBottom: -74,
    },
  },
  selectListItemContainer: {
    marginTop: 14,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 42,
    "& > div:nth-child(odd)": {
      backgroundColor: Colors.catskillWhite,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    }
  },
  innerIcon: {
    color: Colors.azure,
  },
  selectListItem: {
    display: "flex",
    borderBottom: `1px solid ${Colors.horizontalUnderlineGray}`,
    paddingTop: "20px",
    paddingRight: "19px",
    paddingBottom: "20px",
    paddingLeft: "28px",
    [theme.breakpoints.down("sm")]:{
      flexDirection: "column",
      alignItems: "flex-start"
    },

    [theme.breakpoints.up("md")]:{
      flexDirection: "row",
      justifyContent: "space-between",
    },

    "& button": {
      marginLeft: "10px"
    }
  },
  mapStyleAnchor: {
    height: "550px",
    width: "100%",
    overflow: "hidden",
    "& > div": {
      height: '100%',
      width: "100% !important",
    },
    "& .mapboxgl-map": {
      borderRadius: "6px",
      "& + div": {
        height: "100% !important",
        width: "100% !important",
      },
    },
    "& > div:nth-child(2)": {
      width: "100% !important", //Needed to resize map.
      maxHeight: "430px"  //Prevent map overflow
    }
  },
  input: {
    color: Colors.pickledBluewood,
  },
  select: {  //Select field itself
    borderRadius: "2px",
    backgroundColor: Colors.white,
    // minWidth: "300px",
    '[class*="MuiSvgIcon-root"]': {
      color: Colors.white
    },
    "&:focus": {
      backgroundColor: Colors.white,
    },
  },
  selectPrimary: {
    paddingLeft: 10,
    border: `2px solid ${Colors.kashmirBlue}`,
    width: "100%",
    backgroundColor: Colors.white,
    //Native Select styling for Map dropdown
    textTransform: props => props.native ? "capitalize" : "none",
    color: Colors.kashmirBlue,
    fontWeight: "600",
    fontSize: 14,
    [theme.breakpoints.down('xs')]: {
      minWidth: 0,
      textTransform: "none",
      padding: "12px 0 10px 7px",
      fontFamily: "Lato, Verdana, Arial, sans-serif",
    }
  },
  selectMenu: {  //select menu styling
    "& > div": {
      backgroundColor: Colors.white,
    },
    "& > div > ul": {
      backgroundColor: Colors.white,
    },
    "& h6": {
      color: `${Colors.azure} !important`,
    },
  },
  selectMenuPrimary: {  //select menu styling
    "& > div": {
      backgroundColor: Colors.white,
    },
    "& > div > ul": {
      backgroundColor: Colors.white,
    },
  },
  selectMenuItem: {
    fontFamily: "Calluna",
    color: Colors.mineShaft,
    paddingLeft: 12,
    fontSize: 16,
    textTransform: "none",
    "&>:h6": {
      fontFamily: "Calluna !important"
    },
    "&:hover": {
      //backgroundColor: Colors.grayChateau + "!important",
      backgroundColor: `rgba(43, 103, 214, .1) !important`,
      boxShadow: "none",
    },
  },
  buttonDiv:{
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down('sm')]:{
      transform: "translateX(-10px)",
    }
  },
  stateBanner:{
    height: 37,
    backgroundColor: Colors.grayChateau,
    color: Colors.inverted,
    fontFamily: "Lato, Verdana, Arial, sans-serif",
    paddingTop: 7,
    paddingLeft: 28,
    fontWeight: "bold",
  }
}))

export const humanizeDistance = (distance) => {
  if (!distance || isNaN(distance)) return null;
  return parseInt(distance) === 1 ? `${parseInt(distance)} mile away` : `${parseInt(distance)} miles away`;
}

export default function ({ location }) {
  const {selectedState, currentPositionPoint} = location.state || {};
  const [internalSelectedState, setInternalSelectedState] = useState(selectedState);
  const [selectedResourceType, setSelectedResourceType] = useState('none');
  const [isRetrievingPosition, setIsRetrievingPosition] = useState(false);

  const data = useStaticQuery(graphql`
      query CommunityResourceQuery {
          allDatoCmsCommunityResource {
              edges {
                  node {
                      id
                      title
                      notes
                      address
                      city
                      state
                      zip
                      phone
                      websiteUrl
                      category
                      state
                      location {
                          latitude
                          longitude
                      }
                  }
              }
          }
      }
  `)

  const iOS = typeof(navigator) !== "undefined" && typeof(window) !== "undefined" ? /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream : false;

  const mappedCommunityResources = data.allDatoCmsCommunityResource.edges.flatMap(e => e.node);
  const [allCommunityResouces, setAllCommunityResouces] = useState(fromJS(mappedCommunityResources).sortBy(c => c.get('title')));
  const [internalCurrentPositionPoint, setInternalCurrentPositionPoint] = React.useState(currentPositionPoint);

  const clinicsByState = () => {
    return allCommunityResouces.sortBy(c=> c.get('state'))
  }

  let communityResourcesWithDistance = !internalCurrentPositionPoint ? clinicsByState() : allCommunityResouces
    .map(c => {
      try {
        if (!c.getIn(['location', 'latitude']) || !c.getIn(['location', 'longitude'])) {
          return c;
        }
        return c.set('distance', distance(internalCurrentPositionPoint, {
          "type": "Point",
          "coordinates": [c.getIn(['location', 'longitude']), c.getIn(['location', 'latitude'])]
        }, { units: 'miles' }));
      } catch (e) {
        console.warn('Failed to calculate distance for resource:', c.get('title'));
        return c;
      }
    })
    .sortBy(c => c.get('distance') || Infinity); // Resources without distance will be sorted to the end

  let filteredCommunityResources = communityResourcesWithDistance;
  
  if (internalSelectedState !== 'none') {
    filteredCommunityResources = filteredCommunityResources.filter(f => (!internalSelectedState || internalSelectedState === f.get('state')));
  }

  if (selectedResourceType !== 'none') {
    filteredCommunityResources = filteredCommunityResources.filter(f => f.get('category') === selectedResourceType);
  }

  const selectClasses = selectStyles();
  const classes = useStyles({native: iOS});

  let priorClinic = " "

  const checkPriorState = (clinic) => {
    if(clinic.get('state') !== priorClinic){
      priorClinic = clinic.get('state');
      return true
    } else {
      return false
    }
  }

  const uniqueResourceTypes = allCommunityResouces
    .map(resource => resource.get('category'))
    .toSet()
    .toArray();

  return(
    <div className={classes.cardOuterContainer}>
      <IdealSplitTabs
      noPadding
      classes={{root: classes.root,
        customCardBody: classes.customCardBody,
        noPadding: classes.noPadding,
      }}
      tabStyle={{color: "white"}}
      tabsContainer={classes.tabsContainer}
      customTabPanel={
        <div className={clsx(classes.secondTab, classes.visibleSmall, "svg-override-clinic-map")}>
          <CurrentLocationFinder
            classes={{
              innerIcon: classes.innerIcon
            }}
            isRetrievingPosition={isRetrievingPosition}
            onStateSelected={setInternalSelectedState}
            onGetCurrentPosition={setInternalCurrentPositionPoint}
          />
          <div style={{display: "flex", width: "88%", justifyContent: "space-between"}}>
            <StateSelect
              className="state-select-override"
              primary
              blueText
              defaultColor={Colors.mineShaft}
              textColor={Colors.mineShaft}
              value={internalSelectedState || "none"}
              updateValue={setInternalSelectedState}
              rootStyle={{width: "38%"}}
              selectMenuProps={{
                className: clsx(selectClasses.selectMenu, classes.selectMenu, classes.selectMenuPrimary)
              }}
              selectClasses={{
                select: clsx(selectClasses.select, classes.select, classes.selectPrimary)
              }}
              menuItemClasses={{
                root: clsx(selectClasses.selectMenuItem, classes.selectMenuItem),
                input: classes.input
              }}
              iOS={iOS}
              source="community"
            />
            <CommunityResourceTypeSelect
              value={selectedResourceType || "none"}
              updateValue={setSelectedResourceType}
              rootStyle={{width: "60%"}}
              selectMenuProps={{
                className: clsx(selectClasses.selectMenu, classes.selectMenu, classes.selectMenuPrimary)
              }}
              selectClasses={{
                select: clsx(selectClasses.select, classes.select, classes.selectPrimary)
              }}
              menuItemClasses={{
                root: clsx(selectClasses.selectMenuItem, classes.selectMenuItem),
                input: classes.input
              }}
              iOS={iOS}
            />
          </div>
        </div>
      }
      tabs={
        [
          {
            tabStyle: {
              width: "fit-content",
            },
            tabName: "List View",
            tabIcon: List,
            tabContent: (
              <div className={classes.selectListItemContainer}>
                {
                  filteredCommunityResources.map(function(resource) {
                    return(
                    <div>
                        {
                          (!internalCurrentPositionPoint && checkPriorState(resource)) &&
                          <div className={classes.stateBanner}>{resource.get("state")}</div>
                        }
                    <div className={classes.selectListItem}>
                      <div style={{display: "flex", flexDirection: "column"}}>
                        <Typography variant="h3" style={{color: Colors.mineShaft, width: "100%", marginBottom: 10}}>{resource.get('title')}</Typography>
                        {resource.get('notes') && (
                          <Typography variant="body1" style={{color: Colors.tundora, marginBottom: 10}}>{resource.get('notes')}</Typography>
                        )}
                        {
                          isRetrievingPosition &&
                          <Typography variant="h6">Loading...</Typography>
                        }
                        { resource.get('distance') &&
                          <Typography style={{marginBottom: 5}} variant="h5">{humanizeDistance(resource.get('distance'))}</Typography>
                        }
                        <Typography style={{fontSize: 12, maxWidth: 270, color: Colors.tundora, fontWeight: 400}} variant="h6">
                          {resource.get('address') ? resource.get('address') + ', ' : ''}
                          {resource.get('city') ? resource.get('city') + ', ' : ''}
                          {resource.get('state') ? getStateAbbreviation(resource.get('state')) : ''}
                          {resource.get('zip') ? ' ' + resource.get('zip') : ''}
                        </Typography>
                      </div>
                      <div className={classes.buttonDiv}>
                        {resource.get('websiteUrl') && (
                          <Button 
                            onClick={() => window.open(resource.get('websiteUrl'), '_blank')} 
                            color="secondary" 
                            size="sm" 
                            style={{
                              color: Colors.azure,
                              marginLeft: 12,
                            }}
                          >
                            <Typography variant="button">Visit Website</Typography>
                          </Button>
                        )}
                        {resource.get('phone') && (
                          <Button 
                            onClick={() => window.location.href = `tel:${resource.get('phone')}`} 
                            color="primary" 
                            size="sm"
                          >
                            <Typography variant="button">Call</Typography>
                          </Button>
                        )}
                      </div>
                    </div>
                      </div>
                        )
                  })
                }
              </div>
            )
          },
          {
            tabName: "Map View",
            tabIcon: MapIcon,
            tabContent: (
              <div className={classes.mapStyleAnchor} >
                <Map
                data={filteredCommunityResources}
                selectedState={internalSelectedState}
                selectedCoordinates={internalCurrentPositionPoint}
                resourceType="community"
                />
              </div>
            )
          }
        ]
      }
    />
    </div>
  )
}
