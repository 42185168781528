/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "./layout.css"
import "../constants/html_block_styles.scss"
import "../constants/overrides.scss"

import ModifiedHelmet from "./modified-helmet"

import NavHeader from "./NavHeader"
import QuickLinks from "./QuickLinks"
import ContactForm from "./contact-form"
import CustomFooter from "./CustomFooter"

import Colors from "../constants/Colors"
import {useTheme} from "@material-ui/core"
import {useMediaQuery} from "@material-ui/core"

const Layout = ({ children, page, widgets}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      datoCmsSite {
          faviconMetaTags {
              tags
          }
      }
      datoCmsSetting {
        hotlinePhoneNumber
        translationNotice
        defaultStoryImage {
            fluid {
                aspectRatio
                width
                height
                src
                srcSet
                sizes
            }
        }
        colorHeaderImage{
            fluid(maxWidth: 300) {
                ...GatsbyDatoCmsFluid
            }
        }
        whiteHeaderImage{
            url
        }
        colorHeaderImage{
            url
        }
        mainNavigation {
          ...NavigationNodeFields
        }
        quickNav {
          ...NavigationNodeFields
        }
        footerForm {
          ...WidgetForm
        }
          contactUsFormImage {
              fluid {
                  ...GatsbyDatoCmsFluid
              }
          }
          footerNav1Label
          footerNav1Link {
              id
              slug
          }
          footerNav1ExternalLink
          footerNav1Sublinks {
              link {
                  id
                  slug
              }
              label
              externalLink
          }
          footerNav2Label
          footerNav2Link {
              id
              slug
          }
          footerNav2ExternalLink
          footerNav2Sublinks {
              label
              link {
                  id
                  slug
              }
              externalLink
          }
          footerNav3Label
          footerNav3Link {
              id
              slug
          }
          footerNav3ExternalLink
          footerNav3Sublinks {
              label
              link {
                  id
                  slug
              }
              externalLink
          }
          subFooterSublinks {
              label
              link {
                  id
              }
          }
          twitterUrl
          linkedinUrl
          facebookUrl
          vimeoUrl
          footerFormText
      }
    }
  `)

  const triggerChat = (bus) => {
    window.bus.publish("chat:trigger-invitation");
  }


  //8x8 integration
  useEffect(() => {
    //When home page, page attribute will not be present so we'll need to catch it.
    if((page && !page.disable8x8) || !page){
      window.__8x8Chat = {
        uuid: "script_5464263095d687acf1216c5.39899458",
        tenant: "aWRlYWxvcHRpb244ODMwMQ",
        channel: "Chat-Scheduling",
        domain: "https://vcc-na2.8x8.com",
        path: "/.",
        buttonContainerId: "__8x8-chat-button-container-script_5464263095d687acf1216c5.39899458",
        stylesheetURL: `${process.env.GATSBY_SITE_ROOT}/eight_by_eight.css`,
        align: "right",
        onInit: function(bus){
          window.bus = bus;
        }
      };
      // Update the document title using the browser API
      var se = document.createElement("script");
      se.type = "text/javascript";
      se.async = true;
      se.src = window.__8x8Chat.domain + window.__8x8Chat.path + "/CHAT/common/js/chat.js";

      var os = document.getElementsByTagName("script")[0];
      os.parentNode.insertBefore(se, os);
    }
    //Remove 8x8 if present and on disable
  });
  const {datoCmsSite: {faviconMetaTags}, datoCmsSetting: { mainNavigation, quickNav, colorHeaderImage, whiteHeaderImage, hotlinePhoneNumber, defaultStoryImage, footerForm, footerFormText }} = data
  const heroFirst = widgets && widgets.length > 0 && (widgets[0].__typename === "DatoCmsWidgetHero" || widgets[0].__typename === "DatoCmsWidgetClinicSearch" || widgets[0].__typename === "DatoCmsWidgetCommunityResourceSearch")


  const theme = useTheme();
  const smallBreakpoint = useMediaQuery(theme.breakpoints.down('sm'))
  const extraSmallBreakpoint = useMediaQuery(theme.breakpoints.down('xs'))
  return (
      <>
    <ModifiedHelmet favicon={faviconMetaTags}>
      <title>{data.site.siteMetadata.title}</title>
      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
      <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
      <link href="https://fonts.googleapis.com/css?family=Lato:300,400,500,700,900&display=swap" rel="stylesheet" />
      <link rel="stylesheet" href="https://use.typekit.net/buo7tbq.css"/>
      <link rel="stylesheet" href="https://video-react.github.io/assets/video-react.css" />
      {
        process.env.NODE_ENV === "development" &&
        <link rel="stylesheet" href="https://i.icomoon.io/public/ecd6936e48/idl-gatsby/style.css"/>
      }
      {
        process.env.NODE_ENV === "development" &&
        <script defer src="https://i.icomoon.io/public/ecd6936e48/idl-gatsby/svgxuse.js"></script>
      }
      {
        process.env.NODE_ENV === "production" &&
        <link rel="stylesheet" href="https://d1azc1qln24ryf.cloudfront.net/29147/idl-gatsby/style-cf.css?g6bdyf" />
      }
      {
        process.env.NODE_ENV === "production" &&
        <script defer src="https://d1azc1qln24ryf.cloudfront.net/29147/idl-gatsby/svgxuse-cf.js?b4uxmk"></script>
      }
    </ModifiedHelmet>
      {
        page && page.seoMetaTags && (
          <ModifiedHelmet seo={page.seoMetaTags} favicon={faviconMetaTags} />
        )
      }
      <ModifiedHelmet>
        <script src="https://85319dbc83d7462597420cdb58c5b97d.js.ubembed.com" async></script>
      </ModifiedHelmet>
        <QuickLinks
        hotline={hotlinePhoneNumber}
        data={quickNav}
        triggerChat={triggerChat}
        extraSmallBreakpoint={extraSmallBreakpoint}
      />
      {
        heroFirst &&
          <NavHeader
            widgetLinks={mainNavigation.treeChildren}
            color={"transparent"}
            logo={whiteHeaderImage}
            scrollLogo={colorHeaderImage}
            smallBreakpoint={smallBreakpoint}
          />
      }
      {
        !heroFirst &&
          <NavHeader
            widgetLinks={mainNavigation.treeChildren}
            color={Colors.white}
            logo={colorHeaderImage}
            scrollLogo={colorHeaderImage}
            listItemStyling={{
              color: Colors.kashmirBlue
            }}
            heightHack={true}
            secondary={true}
            smallBreakpoint={smallBreakpoint}
          />
      }
    <main>
      {children}
      <ContactForm widget={footerForm}
        text={footerFormText}
        bgImg={data.datoCmsSetting && data.datoCmsSetting.contactUsFormImage}
        triggerChat={triggerChat}
        hotlinePhoneNumber={hotlinePhoneNumber}
      />
      <CustomFooter
        data={data.datoCmsSetting}
      />
      <div style={{display: "none"}} id="__8x8-chat-button-container-script_5464263095d687acf1216c5.39899458"></div>
    </main>
      </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
